import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
            <h3 className="has-text-centered" style={{ color : "#b3722d" }}>
                Merci !
            </h3>
          <p>Nous vous recontacterons sous peu !</p>
        </div>
      </div>
    </section>
  </Layout>
)
